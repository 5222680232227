import { MethodCode, ModelCode } from '../../@types/Permission';
import { findPermission } from '../../sections/@dashboard/Permissions/utils';
var agentPerformance = function (user) {
    var hasPermissionListAgentPerformance = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.AGENT_PERFORMANCE, MethodCode.LIST);
    var hasPermissionViewAgentPerformance = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.AGENT_PERFORMANCE, MethodCode.VIEW);
    var hasPermissionEditAgentPerformance = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.AGENT_PERFORMANCE, MethodCode.EDIT);
    var hasPermissionCreateAgentPerformance = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.AGENT_PERFORMANCE, MethodCode.CREATE);
    var hasPermissionDeleteAgentPerformance = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.AGENT_PERFORMANCE, MethodCode.DELETE);
    return {
        hasPermissionListAgentPerformance: hasPermissionListAgentPerformance,
        hasPermissionViewAgentPerformance: hasPermissionViewAgentPerformance,
        hasPermissionEditAgentPerformance: hasPermissionEditAgentPerformance,
        hasPermissionCreateAgentPerformance: hasPermissionCreateAgentPerformance,
        hasPermissionDeleteAgentPerformance: hasPermissionDeleteAgentPerformance
    };
};
export default agentPerformance;
